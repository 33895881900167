import styled from '@emotion/styled';
import { COLORS, BREAKPOINTS } from './variables';

const UppercaseOrange = styled.div`
  color: ${COLORS.ORANGE};
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 600;

  @media ${BREAKPOINTS.TABLET} {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 2px;
  }
`;

export default UppercaseOrange;
