import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import { css } from "@emotion/core";

import { BREAKPOINTS, COLORS } from "../../ui/variables";
import InstagramIco from "../../images/icons/instagram.png";
import FacebookIco from "../../images/icons/facebook.png";

const Container = styled.div`
  border-top: 2px solid ${COLORS.LIGHT_GRAY};
  height: 80px;
  color: #888;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  position: relative;

  @media ${BREAKPOINTS.MOBILE} {
    height: auto;
    padding: 32px 0 0 0;
  }
`;

const FooterContent = styled.div`
  max-width: 1238px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media ${BREAKPOINTS.MOBILE} {
    flex-direction: column;
  }
`;

const GrayLink = styled(Link)`
  color: #888;
  margin-left: 40px;

  &:first-child {
    margin-left: 0;
  }

  @media ${BREAKPOINTS.MOBILE} {
    width: 100%;
    display: block;
    margin: 0;
    text-align: center;
    margin-bottom: 20px;
  }
`;

const Social = styled.span`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media ${BREAKPOINTS.MOBILE} {
    position: inherit;
    left: auto;
    right: auto;
    transform: none;
    order: -1;
    text-align: center;
    margin-bottom: 32px;
  }
`;

const CopyWrite = styled.div`
  @media ${BREAKPOINTS.MOBILE} {
    text-align: center;
    margin-bottom: 20px;
  }
`;

const SocialImg = styled.img`
  margin: 0;
`;

const Footer = () => (
  <Container>
    <FooterContent>
      <CopyWrite>©{new Date().getFullYear()} Small Door</CopyWrite>
      <Social>
        <a
          href="https://www.instagram.com/smalldoorvet/"
          target="_blank"
          rel="noreferrer"
        >
          <SocialImg
            src={InstagramIco}
            width="20"
            height="20"
            alt="Instagram Logo"
          />
        </a>
        <a
          css={css`
            margin-left: 20px;
          `}
          href="https://www.facebook.com/smalldoorvet/"
          target="_blank"
          rel="noreferrer"
        >
          <SocialImg
            src={FacebookIco}
            width="20"
            height="20"
            alt="Facebook Logo"
          />
        </a>
      </Social>
      <div>
        <GrayLink to="/accessibility">Accessibility</GrayLink>
        <GrayLink to="/legal/privacy">Privacy Policy</GrayLink>
        <GrayLink to="/legal/terms">Terms of Service</GrayLink>
      </div>
    </FooterContent>
  </Container>
);

export default Footer;
