import React from "react";
import styled from "@emotion/styled";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";

import { BREAKPOINTS } from "../../ui/variables";
import ParagraphLarge from "../../ui/ParagraphLarge";
import Heading3 from "../../ui/Heading3";

const OfferingsContainer = styled.div`
  margin-bottom: 80px;
`;

const Offering = styled.div`
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${BREAKPOINTS.MOBILE} {
    flex-direction: column;
  }
`;

const OfferingWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const OfferingPhoto = styled.div<{ right?: boolean; left?: boolean }>`
  margin-left: ${({ right }) => (right ? "108px" : "0")};
  margin-right: ${({ left }) => (left ? "108px" : "0")};
  max-width: 432px;
  width: 100%;
  flex-shrink: 0;

  @media ${BREAKPOINTS.MOBILE} {
    margin: 0 0 40px 0;
    order: -1;
  }
`;

const OfferingContent = styled(ParagraphLarge)`
  display: block;
`;

const Offerings = () => {
  const data = useStaticQuery(graphql`
    query {
      telemedicine: file(
        relativePath: {
          eq: "pages/direct-mail-landing/offerings/24-7-telemedicine.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 432, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      convenient: file(
        relativePath: {
          eq: "pages/direct-mail-landing/offerings/convenient.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 432, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      exceptional: file(
        relativePath: {
          eq: "pages/direct-mail-landing/offerings/exceptional-doctors.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 432, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      fullService: file(
        relativePath: {
          eq: "pages/direct-mail-landing/offerings/full-service.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 432, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      stressFree: file(
        relativePath: {
          eq: "pages/direct-mail-landing/offerings/stress-free.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 432, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <OfferingsContainer>
      <Offering>
        <OfferingWrap>
          <Heading3>More time with exceptional doctors</Heading3>

          <OfferingContent>
            Our doctors provide best-in-class care and we're among just 15% of
            veterinary hospitals in the USA to be recognized by the American
            Animal Hospital Association (AAHA). By being members-only, we ensure
            you get more time with your doctor. Our longer appointment times
            means you never feel rushed. Our doctors have the time to explain
            all treatment options and help you decide the best course of action.
          </OfferingContent>
        </OfferingWrap>
        <OfferingPhoto right>
          <Img
            fluid={data.exceptional.childImageSharp.fluid}
            alt="Doctor holding dog alongside dog's owner"
          />
        </OfferingPhoto>
      </Offering>

      <Offering>
        <OfferingPhoto left>
          <Img
            fluid={data.convenient.childImageSharp.fluid}
            alt="Dog with owner"
          />
        </OfferingPhoto>
        <OfferingWrap>
          <Heading3>Convenient, priority appointments</Heading3>

          <OfferingContent>
            We cap our member numbers to ensure you can always get care when you
            need it at any of our five NYC locations (West Village, Upper East
            Side, Gramercy, Williamsburg, and the Upper West Side). You can also
            book and manage your appointments, prescription requests, and
            vaccine certificates online or in our app - no waiting on hold.
          </OfferingContent>
        </OfferingWrap>
      </Offering>

      <Offering>
        <OfferingWrap>
          <Heading3>Unlimited 24/7 telemedicine</Heading3>

          <OfferingContent>
            Pet parenthood isn't a 9-5 gig. With our 24/7 telemedicine, you can
            chat with us any time to get advice and peace of mind in just a few
            minutes from a dedicated medical team who has all of your pet's
            history.
          </OfferingContent>
        </OfferingWrap>
        <OfferingPhoto right>
          <Img
            fluid={data.telemedicine.childImageSharp.fluid}
            alt="Person with dog, using cell phone"
          />
        </OfferingPhoto>
      </Offering>

      <Offering>
        <OfferingPhoto left>
          <Img
            fluid={data.fullService.childImageSharp.fluid}
            alt="Small Door exam room"
          />
        </OfferingPhoto>
        <OfferingWrap>
          <Heading3>Full-service in one place</Heading3>
          <OfferingContent>
            Our onsite lab, radiology, dental, and surgical suites ensure you
            get full-service care in one place without having to hop around the
            city to get the comprehensive care your pet needs.
          </OfferingContent>
        </OfferingWrap>
      </Offering>

      <Offering>
        <OfferingWrap>
          <Heading3>Stress-free spaces</Heading3>
          <OfferingContent>
            Our modern, state-of-the-art veterinary practices feature private
            spaces, easy-grip surfaces, soft furnishings, natural lighting, and
            anxiety reducing scents so your pet looks forward to their visits.
          </OfferingContent>
        </OfferingWrap>
        <OfferingPhoto right>
          <Img
            fluid={data.stressFree.childImageSharp.fluid}
            alt="Women sitting with dog inside Small Door"
          />
        </OfferingPhoto>
      </Offering>
    </OfferingsContainer>
  );
};

export default Offerings;
