import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { graphql, useStaticQuery } from "gatsby";
import { FixedObject } from "gatsby-image";

import UserStarAvatar from "../UserStarAvatar";
import Heading2 from "../../ui/Heading2";
import YelpLogo from "../../images/pages/direct-mail-landing/yelp-logo.svg";
import GoogleReviewsLogo from "../../images/pages/direct-mail-landing/google-reviews-logo.svg";
import { BREAKPOINTS } from "../../ui/variables";
import Heading3 from "../../ui/Heading3";

const Container = styled.div`
  max-width: 809px;
  width: 100%;
  margin: auto;

  @media ${BREAKPOINTS.TABLET} {
    padding: 0 20px 0 20px;
  }
`;

const Review = styled.div`
  margin-bottom: 40px;

  @media ${BREAKPOINTS.MOBILE} {
    margin-bottom: 60px;
  }
`;

const ReviewFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 22px;
`;

interface Review {
  name: string;
  review: string;
  starCount: number;
  avatar: FixedObject;
  source: "yelp" | "google";
}

const Reviews = () => {
  const data = useStaticQuery(graphql`
    query {
      reviewer1: file(relativePath: { eq: "pages/direct-mail-landing/nikki.jpg" }) {
        childImageSharp {
          fixed(width: 60) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      reviewer2: file(relativePath: { eq: "pages/direct-mail-landing/faith.jpg" }) {
        childImageSharp {
          fixed(width: 60) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      reviewer3: file(relativePath: { eq: "pages/direct-mail-landing/johnelle.jpg" }) {
        childImageSharp {
          fixed(width: 60) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const reviews: Review[] = [
    {
      name: "Nikki B.",
      starCount: 5,
      review: "I absolutely love Small Door! I have messaged with vets several times now about things I thought were a big deal, like Frankie having a cough or experiencing mild stomach issues, that ended up having easy at-home treatment and saved me a vet visit.",
      avatar: data.reviewer1.childImageSharp.fixed,
      source: "yelp",
    },
    {
      name: "Faith L.",
      starCount: 5,
      review: "Small Door is an amazing service! The doctors are caring and take their time to assess all issues with your pet. I left my first appointment with them more educated on my dog's issues than any other vet apt!",
      avatar: data.reviewer2.childImageSharp.fixed,
      source: "google",
    },
    {
      name: "Johnelle P.",
      starCount: 5,
      review: "Being a first time dog mom, I’m extremely nervous when it comes to health concerns about my pup. Their 24/7 chat eases my anxiety 101%! Even if I send a message over at 4AM someone is always available to help out.",
      avatar: data.reviewer3.childImageSharp.fixed,
      source: "google",
    },
  ];

  return (
    <Container>
      <Heading3
        css={css`
          margin-bottom: 60px;
        `}
      >
        We are proud to be one of the highest rated veterinary practices on
        Google and Yelp, with hundreds of five-star reviews.
      </Heading3>

      {reviews.map(review => (
        <Review key={review.name}>
          {review.review}
          <ReviewFooter>
            <UserStarAvatar
              css={css`
                margin: 0;
              `}
              starCount={review.starCount}
              name={review.name}
              fixedImage={review.avatar}
            />
            {review.source === "yelp" && <img src={YelpLogo} alt="Yelp Logo" />}
            {review.source === "google" && (
              <img src={GoogleReviewsLogo} alt="Google Reviews Logo" />
            )}
          </ReviewFooter>
        </Review>
      ))}
    </Container>
  );
};

export default Reviews;
