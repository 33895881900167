import React from "react";
import GlobalStyles from "./GlobalStyles";

import "./layout.css";

interface Props {
  children?: React.ReactNode;
}

const IndividualPageLayout = ({ children }: Props) => {
  return (
    <div>
      <GlobalStyles />
      <main>{children}</main>
    </div>
  );
};

export default IndividualPageLayout;
