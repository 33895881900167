import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import { css } from "@emotion/core";

import Heading2 from "../../ui/Heading2";
import { ReactComponent as ArrowRightIco } from "../../images/icons/arrow-right.svg";
import { BREAKPOINTS, COLORS } from "../../ui/variables";
import Heading3 from "../../ui/Heading3";

const Container = styled.div`
  max-width: 809px;
  width: 100%;
  margin: auto;
  margin-top: 140px;
  margin-bottom: 120px;

  @media ${BREAKPOINTS.TABLET} {
    padding: 0 20px;
  }
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  list-style: none;

  li {
    font-size: 16px;
    margin-bottom: 32px;
    width: 45%;
    flex-shrink: 0;

    @media screen and (max-width: 700px) {
      width: 100%;
    }
  }
`;

const LocationLink = styled(Link)`
  font-size: 24px;
  font-weight: 600;
  line-height: 38px;
  display: flex;
  align-items: center;

  @media ${BREAKPOINTS.MOBILE} {
    line-height: 26px;
    font-size: 20px;
  }
`;

const Arrow = () => (
  <ArrowRightIco
    width="20"
    css={css`
      fill: ${COLORS.ORANGE};
      margin-left: 12px;
    `}
  />
);

const Locations = () => (
  <Container>
    <Heading3>Visit any of our locations</Heading3>
    <List>
      <li>
        <LocationLink to="/locations/new-york-city/west-village">
          West Village <Arrow />
        </LocationLink>
        15 7th Avenue, New York, NY 10011
      </li>

      <li>
        <LocationLink to="/locations/new-york-city/upper-west-side">
          Upper West Side <Arrow />
        </LocationLink>
        667 Columbus Ave, New York, NY 10025
      </li>

      <li>
        <LocationLink to="/locations/new-york-city/upper-east-side">
          Upper East Side <Arrow />
        </LocationLink>
        1231 3rd Avenue, New York, NY 10021
      </li>

      <li>
        <LocationLink to="/locations/new-york-city/gramercy">
          Gramercy <Arrow />
        </LocationLink>
        380 2nd Avenue, New York, NY 10010
      </li>

      <li>
        <LocationLink to="/locations/new-york-city/williamsburg">
          Williamsburg <Arrow />
        </LocationLink>
        118 N 4th St, Brooklyn, NY 11249
      </li>
    </List>
  </Container>
);

export default Locations;
